<script>
	import { scale, slide } from "svelte/transition";

	export let rating;
	export let hovering;
</script>

{#if hovering}	
	<div class="ratings">
		{#each Array(rating) as i}
			<div class="rating-star" in:slide out:scale>
				<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					viewBox="0 0 47.94 47.94" style="enable-background:new 0 0 47.94 47.94;" xml:space="preserve">
					<path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
					c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
					c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
					c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
					c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
					C22.602,0.567,25.338,0.567,26.285,2.486z"/>
				</svg>
			</div>
		{/each}
	</div>
{/if}

<style>
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes popIn {
		from {
			transform: scale(0.5);
			opacity: 0;
		}
		to {
			transform: scale(1);
			opacity: 1;
		}
	}

	.ratings {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding: 0.5rem;
		border-radius: 8px;
		background: rgba(0, 0, 0, 0.35);
		backdrop-filter: blur(10px) saturate(180%);
		-webkit-backdrop-filter: blur(10px) saturate(180%);
		animation: popIn 0.25s forwards;
	}

	.rating-star {
		width: 1.25rem;
		transform: var(--card-transform);
		animation: fadeIn 0.3s backwards;
	}

	.rating-star:nth-child(1) {
		animation-delay: 0.05s;
	}
	.rating-star:nth-child(2) {
		animation-delay: 0.1s;
	}
	.rating-star:nth-child(3) {
		animation-delay: 0.15s;
	}
	.rating-star:nth-child(4) {
		animation-delay: 0.18s;
	}
	.rating-star:nth-child(5) {
		animation-delay: 0.19s;
	}

	.rating-star:not(:last-child) {
		margin-right: 0.25rem;
	}

	.rating-star svg {
		width: 100%;
		height: 100%;
		fill: #ffd32a;
	}
</style>