
<script>
	import Rating from './rating.svelte';
	import { scale } from "svelte/transition";
	export let poster, title, rating;

	let cardTransform;
	let cardContentTransform;
	let cardTransformScaleX = 0.05;
	let cardTransformScaleY = cardTransformScaleX * 1.5;
	let root = document.documentElement;
	let rotateX = 0;
	let rotateY = 0;
	let transformZ = 0;
	let hovering = false;

	function handleMousemove(event) {
		rotateX =  (event.offsetY - event.target.clientHeight / 2) * (cardTransformScaleX * (event.target.clientWidth/100));
		rotateY =  (event.offsetX - event.target.clientWidth / 2) * (cardTransformScaleY * (event.target.clientWidth/100));
		transformZ = event.target.clientWidth / 10;
		cardTransform = 'rotateZ(' + rotateY * 0 + 'deg) rotateX(' + -rotateX + 'deg) rotateY(' + rotateY + 'deg) translateZ(' + transformZ + 'px)';
		cardContentTransform = 'rotateZ(' + rotateY * 0.5 + 'deg) rotateX(' + -rotateX + 'deg) rotateY(' + rotateY + 'deg) translateZ(' + transformZ + 'px)';
		root.style.setProperty('--card-transform', cardTransform);
		root.style.setProperty('--card-content-transform', cardContentTransform);
		hovering = true;
	}
</script>

{#if poster}
<div on:mousemove={handleMousemove} class="card-wrapper">
	<div class="card" in:scale out:scale>
		<div class="card-image">
			<img src="{poster}" alt="{title}">
		</div>
		<div class="card-content">
			<Rating rating={rating} hovering={hovering}/>
		</div>
	</div>
</div>
{/if}

<style>
	.card-wrapper {
		cursor:pointer;
		transition: transform 0.2s ease-in-out;
		perspective: 600px;
	}

	.card {
		position: relative;
		width: 100%;
		aspect-ratio: 1/1.5;
		border-radius: 4px;
		box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.2);
		transition: box-shadow 0.2s ease-in-out;
		transform: var(--card-transform);
		transform-origin: 50% 50%;
		background-color: #fff;
		background: var(--card-background);
		background-size: cover;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items:flex-end;
		padding: 1rem;
	}

	.card .card-image {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #fff;
	}

	.card .card-image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.card .card-content {
		display: none;
	}

	.card * {
		pointer-events: none;
	}

	.card:hover {
		box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
	}

	.card-wrapper:not(:hover) .card {
		transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		--card-transform: rotateY(0deg) rotateX(0deg);
		--card-content-transform: rotateY(0deg) rotateX(0deg);
	}

	.card-wrapper:hover {
		transform: scale(1.05);
	}

	.card-wrapper:hover .card-content {
		display: block;
	}
</style>