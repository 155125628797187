<script>
	import { onMount } from 'svelte';
	import Card from './components/card.svelte';
	import movielist from './moviedb.json';

	let numberOfMovies = 36;
	let movies = [];
	let tempMovies = [
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		},
		{
			title: "",
			poster: "",
			rating: ""
		}
	];

	movielist.map(movie => {
		let movieID = String(movie.IMDB);
		movieID.length < 7 ? movieID = movieID.padStart(7, '0') : movieID = movie.IMDB;
		let movieObj = {
			imdb: movieID,
			rating: movie.rating
		}
		movies.push(movieObj);
	});

	function getMovies(movieCount) {
		for (let i = 0; i < movieCount; i++) {
			let movieURL = 'https://www.omdbapi.com/?i=tt' + movies[i].imdb + '&apikey=1da7abd7';

			fetch(movieURL).then((response) => {
				return response.json()
			})
			.then((data) => {
				tempMovies[i].poster = data.Poster;
				tempMovies[i].title = data.Title;
				tempMovies[i].rating = movies[i].rating;
			})
			.catch((err) => {
			});

		}
	}
	
	onMount(() => {
		getMovies(numberOfMovies);
	});
</script>

<div class="card-wrapper">
	<div class="cards">
		{#each tempMovies as movie}
			<Card
				title={movie.title}
				poster={movie.poster}
				rating={movie.rating}
			/>
		{/each}
	</div>
</div>

<style>
	.card-wrapper {
		display: flex;
		min-height: 100vh;
		width: 100vw;
		padding: 2rem;
		overflow: auto;
	}

	.cards {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
		grid-template-rows: auto;
		grid-gap: 2rem;
		width: 100%;
	}
</style>